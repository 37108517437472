import styled from 'styled-components';
import { ContainerStyle } from '../../theme/index';

const Container = styled(ContainerStyle)`
  padding-top: 10vh;
  min-height: 64vh;
  padding-bottom: 32px;
  z-index: 3;
  position: relative;
  &.newsHero {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 64px;
    h1:first-child {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 22px;
      margin-bottom: 14px;
      small {
        font-size: 12px;
        letter-spacing: 0.30px;
      }
    }
    h1:nth-child(2) {
      font-size: 48px;
      letter-spacing: -0.16px;
      line-height: 52px;
      @media (max-width: 768px){
        font-size: 32px;
        line-height: 36px;
      }
      @media screen and (max-width: 667px){
        font-size: 28px;
      }
    }
  }
  &.blogHero {
    min-height: inherit;
  }
  

  @media (max-width: 768px){
    min-height: unset;
    padding-top: 64px;
    padding-bottom: 0px;
    h3 {
      font-size: 20px;
    }
  }

  @media (min-width: 1340px){
    min-height: 640px;
  }
  section {
    width: 32vw;
    margin-top: 64px;
    @media screen and (max-width: 768px){
      width: 100%;
      margin-top: 32px;
    }
    .newsHero& {
      width: 100%;
      margin-top: 32px;
      p {
        font-size: 24px;
        letter-spacing: 0.16px;
        line-height: 32px;
        @media (max-width: 768px){
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
  
`;

export default Container;
