import React from 'react';

import ThemeProvider from 'stoomlink-commons-ui/ThemeProvider/ThemeProvider';
import styled from 'styled-components';
import Layout from '../layouts/Layout';
import Container from '../layouts/Container';
import Head from '../layouts/Head';

import Hero from '../components/Hero';
import Button from '../components/Button';

const StyledButton = styled(Button)`
  margin: 16px 0 64px 0;
`;

function NotFoundPage() {
  return (
    <ThemeProvider themeStyle="light">
      <Layout>
        <Head title="Page Not Found" />
        <Container>
          <Hero>
            <iframe title="Not Found ChildFocus" src="https://notfound-static.fwebservices.be/404?key=" width="100%" height="600" frameBorder="0" />
            <StyledButton href="/">Back to Home</StyledButton>
          </Hero>
        </Container>
      </Layout>
    </ThemeProvider>
  );
}

export default NotFoundPage;
