import PropTypes from 'prop-types';
import React from 'react';
import Title from 'stoomlink-commons-ui/Title';

import Container from './styledComponent';


const Hero = ({
  suptitle, title, children, layout, className,
}) => {
  return (
    <Container layout={layout} className={className}>
      {suptitle && (
        <Title
          dangerouslySetInnerHTML={{
            __html: suptitle,
          }}
          level={1}
        />
      )}
      <Title
        dangerouslySetInnerHTML={{
          __html: title,
        }}
        displayLevel={3}
      />
      {children && (
        <section>
          {children}
        </section>
      )}
    </Container>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  suptitle: PropTypes.string,
  children: PropTypes.node,
};

export default Hero;
